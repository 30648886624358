//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { retrieveButter,
         getDictionary,
         getLang } from '~/utils/butterUtils'
import { getVFAParameters } from '~/utils/VFAParameters'

export default {
  head () {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.url+this.$route.path
        },
        {
          rel: 'alternate',
          href: '/',
          hreflang: 'x-default'
        },
        {
          rel: 'alternate',
          href: '/esES',
          hreflang: 'x-default'
        },
      ]
    }
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/index.vue asyncData ')
  },
  data () {
    return {
      selectedState: null
    }
  },
  computed: {
    isStudentSite () {
      return process.env.isStudentSite === 'true' || this.$store.state.isSimulatedStudentSite
    },
    lang () {
      return getLang(this.$i18n.locale)
    },
    pageMetaDescription () {
      return this.dict.SEO_A02 /* this.dict.SEO_A09 // "US Citizens living abroad can request absentee ballots easily. Start your ballot request or find your state’s voting rules here." */ /* this.dict.SEO_A06 */
    },
    pageTitle () {
      return this.dict.SEO_A04 /* "Vote from Abroad: Request Absentee Ballots for US Citizens" */ /* process.env.siteTitle + " | " + this.dict.SEO_A05 */
    },
    buttonBackground () {
      return this.butterVFAGraphics["landing_page_new_voter_button_"+this.lang]
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics.landing_page : null
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE () {
      return this.VFAParameters.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE
    },
  },
  methods: {
    goStartFPCA () {
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit("clearVoterData");
      this.$store.dispatch("userdata/clearVoterData");
      this.$router.push(
        this.localePath({
          name: "request-stage",
          params: { stage: "your-information" },
        })
      );
    },
  },
  mounted () {
    this.$store.commit('saveSelectedState', '')
    localStorage.removeItem('localstoragevotingstate')
  },
  transition: 'test'
}
